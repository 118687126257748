import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './WebsiteList.module.css';
import { websiteService } from '../../services/api';

// Simple icons using SVG for better cross-browser compatibility
const AddIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <line x1="12" y1="5" x2="12" y2="19"></line>
    <line x1="5" y1="12" x2="19" y2="12"></line>
  </svg>
);

const FlashIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
  </svg>
);

const DashboardIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <rect x="3" y="3" width="7" height="7"></rect>
    <rect x="14" y="3" width="7" height="7"></rect>
    <rect x="14" y="14" width="7" height="7"></rect>
    <rect x="3" y="14" width="7" height="7"></rect>
  </svg>
);

const SettingsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={styles.settingsIcon}>
    <circle cx="12" cy="12" r="3"></circle>
    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
  </svg>
);

const DeleteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={styles.deleteIcon}>
    <path d="M3 6h18"></path>
    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
    <line x1="10" y1="11" x2="10" y2="17"></line>
    <line x1="14" y1="11" x2="14" y2="17"></line>
  </svg>
);

const InfoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={styles.emptyStateIcon}>
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="12" y1="16" x2="12" y2="12"></line>
    <line x1="12" y1="8" x2="12.01" y2="8"></line>
  </svg>
);

// Format date function
const formatDate = (dateString) => {
  if (!dateString) return '-';

  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return '-';

    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  } catch (error) {
    console.error('Error formatting date:', error);
    return '-';
  }
};

const ApplicationList = () => {
  const navigate = useNavigate();
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentApp, setCurrentApp] = useState(null);
  const [newApp, setNewApp] = useState({ name: '', domain: '', timezone: 'UTC' });
  const [domainError, setDomainError] = useState('');

  // Fetch applications on component mount
  useEffect(() => {
    const fetchApplications = async () => {
      try {
        setLoading(true);
        console.log('Fetching applications...');
        const response = await websiteService.getUserWebsites();
        console.log('Applications fetched:', response.data);
        setApplications(response.data);
      } catch (err) {
        console.error('Error fetching applications:', err);
        setError('Failed to load applications. Please try again later.');
        setApplications([]);
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
  }, []);

  const validateDomain = (domain) => {
    if (domain.startsWith('http://') || domain.startsWith('https://')) {
      return {
        isValid: false,
        message: 'Please enter the domain without http:// or https://'
      };
    }

    if (domain.includes('/')) {
      return {
        isValid: false,
        message: 'Please enter only the domain name without any paths'
      };
    }

    const domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]*(\.[a-zA-Z0-9][a-zA-Z0-9-]*)+$/;
    if (!domainRegex.test(domain)) {
      return {
        isValid: false,
        message: 'Please enter a valid domain format (e.g., example.com)'
      };
    }

    return { isValid: true, message: '' };
  };

  const handleAddApplication = async (e) => {
    e.preventDefault();

    const validation = validateDomain(newApp.domain);
    if (!validation.isValid) {
      setDomainError(validation.message);
      return;
    }

    try {
      setLoading(true);
      const response = await websiteService.createWebsite(newApp);
      setApplications([...applications, response.data]);
      setShowAddModal(false);
      setNewApp({ name: '', domain: '', timezone: 'UTC' });
      navigate(`/applications/${response.data.id}/setup`);
    } catch (err) {
      console.error('Error adding application:', err);
      setError('Failed to add application. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteApplication = async () => {
    if (!currentApp) return;

    try {
      setLoading(true);
      await websiteService.deleteWebsite(currentApp.id);
      setApplications(applications.filter(app => app.id !== currentApp.id));
      setShowDeleteModal(false);
      setCurrentApp(null);
    } catch (err) {
      console.error('Error deleting application:', err);
      setError('Failed to delete application. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const getActionButton = (app) => {
    const status = app?.status?.toLowerCase() || 'pending';

    if (status === 'active') {
      return (
        <button
          className={`${styles.actionButton} ${styles.primaryButton}`}
          onClick={() => navigate(`/dashboard/${app.id}`)}
        >
          <DashboardIcon /> View Dashboard
        </button>
      );
    } else {
      return (
        <button
          className={`${styles.actionButton} ${styles.warningButton}`}
          onClick={() => navigate(`/applications/${app.id}/setup`)}
        >
          <FlashIcon /> Complete Setup
        </button>
      );
    }
  };

  const handleViewDashboard = (app) => {
    const status = app?.status?.toLowerCase() || 'pending';

    if (status === 'active') {
      navigate(`/dashboard/${app.id}`);
    } else {
      navigate(`/applications/${app.id}/setup`);
    }
  };

  if (loading && applications.length === 0) {
    return (
      <div className={styles.container} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <div className={styles.loader}></div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>Your Applications</h1>
        <button className={styles.addButton} onClick={() => setShowAddModal(true)}>
          <AddIcon /> Add New App
        </button>
      </div>

      {error && (
        <div className={styles.errorAlert}>
          {error}
        </div>
      )}

      {applications.length > 0 ? (
        <div className={styles.appGrid}>
          {applications.map((app) => {
            const isPending = app.status?.toLowerCase() !== 'active';

            return (
              <div
                key={app.id}
                className={`${styles.appCard} ${isPending ? styles.pendingCard : ''}`}
              >
                <div className={styles.cardContent}>
                  <div className={styles.appInfo}>
                    <h2
                      className={styles.appName}
                      onClick={() => handleViewDashboard(app)}
                    >
                      {app.name}
                    </h2>
                    <p className={styles.appUrl}>{app.domain}</p>
                  </div>

                  <div className={styles.statusSection}>
                    <span className={`${styles.chip} ${isPending ? styles.chipWarning : styles.chipSuccess}`}>
                      {isPending ? 'Pending' : 'Active'}
                    </span>
                    <span className={styles.dateText}>
                      Added: {formatDate(app.created_at)}
                    </span>
                  </div>

                  <div className={styles.actions}>
                    {getActionButton(app)}
                    <div className={styles.actionButtons}>
                      <button
                        className={styles.iconButton}
                        onClick={() => navigate(`/applications/${app.id}/edit`)}
                        aria-label="Edit application settings"
                      >
                        <SettingsIcon />
                      </button>
                      <button
                        className={styles.iconButton}
                        onClick={() => {
                          setCurrentApp(app);
                          setShowDeleteModal(true);
                        }}
                        aria-label="Delete application"
                      >
                        <DeleteIcon />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles.emptyState}>
          <InfoIcon />
          <h2 className={styles.emptyStateHeading}>No applications yet</h2>
          <p className={styles.emptyStateText}>Click "Add New App" to get started with Analytics Hub.</p>
          <button className={styles.centeredAddButton} onClick={() => setShowAddModal(true)}>
            <AddIcon /> Add New App
          </button>
        </div>
      )}

      {/* Add Application Modal */}
      {showAddModal && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <div className={styles.modalHeader}>
              <h2 className={styles.modalTitle}>Add New Application</h2>
            </div>

            <form onSubmit={handleAddApplication}>
              <div className={styles.modalBody}>
                <div className={styles.formGroup}>
                  <label className={styles.formLabel}>
                    Application Name
                  </label>
                  <input
                    type="text"
                    placeholder="My Awesome App"
                    value={newApp.name}
                    onChange={(e) => setNewApp({ ...newApp, name: e.target.value })}
                    required
                    className={styles.formInput}
                  />
                </div>

                <div className={styles.formGroup}>
                  <label className={styles.formLabel}>
                    Domain Name
                  </label>
                  <input
                    type="text"
                    placeholder="example.com"
                    value={newApp.domain}
                    onChange={(e) => {
                      const domain = e.target.value;
                      setNewApp({ ...newApp, domain });
                      const validation = validateDomain(domain);
                      setDomainError(validation.isValid ? '' : validation.message);
                    }}
                    required
                    className={`${styles.formInput} ${domainError ? styles.inputError : ''}`}
                  />
                  {domainError && (
                    <p className={styles.errorText}>
                      {domainError}
                    </p>
                  )}
                  {!domainError && (
                    <p className={styles.helperText}>
                      Enter the domain without 'http://' or 'https://'
                    </p>
                  )}
                </div>

                <div className={styles.formGroup}>
                  <label className={styles.formLabel}>
                    Reporting Timezone
                  </label>
                  <select
                    value={newApp.timezone || "UTC"}
                    onChange={(e) => setNewApp({ ...newApp, timezone: e.target.value })}
                    className={styles.formSelect}
                  >
                    <option value="UTC">UTC</option>
                    <option value="America/New_York">(GMT-04:00) America/New_York</option>
                    <option value="America/Chicago">(GMT-05:00) America/Chicago</option>
                    <option value="America/Denver">(GMT-06:00) America/Denver</option>
                    <option value="America/Los_Angeles">(GMT-07:00) America/Los_Angeles</option>
                    <option value="Europe/London">(GMT+01:00) Europe/London</option>
                    <option value="Europe/Paris">(GMT+02:00) Europe/Paris</option>
                    <option value="Asia/Tokyo">(GMT+09:00) Asia/Tokyo</option>
                    <option value="Australia/Sydney">(GMT+10:00) Australia/Sydney</option>
                  </select>
                  <p className={styles.helperText}>
                    To make sure we agree on what 'today' means
                  </p>
                </div>
              </div>

              <div className={styles.modalFooter}>
                <button
                  type="button"
                  onClick={() => setShowAddModal(false)}
                  className={styles.cancelButton}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={!newApp.name || !newApp.domain || !!domainError}
                  className={styles.submitButton}
                >
                  Add Application
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Delete Modal */}
      {showDeleteModal && currentApp && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <div className={styles.deleteModalHeader}>
              <h2 className={styles.deleteModalTitle}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ marginRight: '0.5rem' }}>
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="12" y1="8" x2="12" y2="12"></line>
                  <line x1="12" y1="16" x2="12.01" y2="16"></line>
                </svg>
                Delete Application
              </h2>
            </div>

            <div className={styles.modalBody}>
              <p className={styles.deleteWarningText}>
                Are you sure you want to delete <strong>{currentApp.name}</strong>?
              </p>
              <p className={styles.deleteDescriptionText}>
                This action cannot be undone. All analytics data for this application will be permanently deleted.
              </p>
            </div>

            <div className={styles.modalFooter}>
              <button
                type="button"
                onClick={() => setShowDeleteModal(false)}
                className={styles.cancelButton}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleDeleteApplication}
                className={styles.deleteButton}
              >
                Delete Application
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplicationList;